import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Ritesh Gupta, ATS Noida",
  address: "Noida",
  client: "Ritesh Gupta",
  area: "3,500 Sq. Ft.",
  project: "Ritesh Gupta, ATS Noida",
  category: "Interiors | Residential",
  backlink: "/projects/interiors/",
};

export default class Projectriteshguptaatsnoida extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Interior Projects | Ritesh Gupta, ATS Noida"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Take a walk through the stunning bespoke modern interiors and décor designed for a private residence in Noida."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
